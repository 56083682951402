header {
  grid-area: header;
  z-index: 2;
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: center;
  padding-inline: 1em;

  @include at1200 {
    padding-inline: clamp(2rem, 3vw, 5rem);
  }
}

.nav-wrapper {
  align-self: center;
  justify-self: end;
  height: 100%;

  @include at1200 {
    align-content: center;
  }
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-color;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 0;
  overflow: hidden;

  @include at1200 {
    position: relative;
    background-color: transparent;
    justify-content: end;
    height: 100%;
    overflow: visible;
    align-items: center;
  }
}

.menu-toggler:checked ~ nav {
  height: 100%;
}

nav > ul {
  margin-block-start: 50px;
  list-style: none;
  display: flex;
  flex-direction: column;

  @include at1200 {
    flex-direction: row;
    margin-block-start: 0;
  }
}

nav > ul > li {
  padding-block: .5em;

  @include at1200 {
    padding-block: unset;
    padding-inline-start: 2.5em;
  }
}

nav > ul > li > a {
  text-decoration: none;
  color: $text-light-accent-color;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: .05em;

  @include at1200 {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
  }
}

nav > ul > li > a > svg {
  width: 2rem;
  vertical-align: middle;
  margin-block-end: .2em;

  @include at1200 {
    margin-block-end: .125em;
    width: clamp(1rem, 1.5vw, 1.5rem);
  }
}

.showreel-link {
  @include at1200 {
    color: $text-light-accent-color;
    background-image: linear-gradient(180deg, $accent-color-gradient, $accent-color);
    letter-spacing: .05em;
    border-radius: .5em;
    padding: .25em 1em;
    box-shadow: $shadow;
  }
}

.menu-toggler {
  position: absolute;
  top: -9999%;
  left: -9999%; 

  @include at1200 {
    display: none;
  }
}

.menu-toggler-label {
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;

  @include at1200 {
    display: none;
  }
}


.menu-toggler-label span,
.menu-toggler-label span::before,
.menu-toggler-label span::after {
  display: block;
  background: $text-light-color;
  height: 3px;
  width: 2em;
  border-radius: 2px;
  position: relative;
  z-index: 1;
  transition: all .2s ease-in;
}

.menu-toggler:checked ~ .menu-toggler-label span, 
.menu-toggler:checked ~ .menu-toggler-label span::before, 
.menu-toggler:checked ~ .menu-toggler-label span::after {
  background: $text-light-accent-color;
  transition: all .2s ease-in;
}

.menu-toggler-label span::before,
.menu-toggler-label span::after {
  content: '';
  position: absolute;
}

.menu-toggler:checked ~ .menu-toggler-label span::before,
.menu-toggler:checked ~ .menu-toggler-label span::after {
  transform-origin: center;
}

.menu-toggler-label span::before {
  top: -8px;
}

.menu-toggler-label span::after {
  top: 8px;
}

.menu-toggler:checked ~ .menu-toggler-label span {
  transform: rotate(45deg);
}

.menu-toggler:checked ~ .menu-toggler-label span::before {
  top: 0;
  transform: rotate(90deg);
}

.menu-toggler:checked ~ .menu-toggler-label span::after {
  top: 0;
}

