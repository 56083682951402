@import 'https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;700&family=Poppins:wght@700;900&display=swap';

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  height: 100%;
  width: 100%;
}

html.noscroll {
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;
  background-color: $bg-color;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 70vh auto;
  grid-template-areas: "header" "hero" "content";

  @include at1200 {
    max-height: 100%;
    overflow: hidden;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: clamp(60px, 5vw, 80px) calc(100% - clamp(60px, 5vw, 80px));
    grid-template-areas: "header header" "hero content"
  }
}

body.noscroll {
  overflow: hidden;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: $text-light-color;
  letter-spacing: .05em;
  text-shadow: $shadow;

  @include at1200 {
    font-size: clamp(1.5rem, 2vw, 2rem);
  }
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1.25rem;
  color: $text-dark-color;
  letter-spacing: .05em;
  padding-inline: .5rem;
  text-align: center;
  margin-block-end: 1em;

  @include at1200 {
    color: $text-light-color;
    font-size: 1.5rem;
    text-align: start;
    padding-inline: clamp(2rem, 3vw, 5rem);
  }
}

h2.bio-heading {
  @include at1200 {
    display: none;
  }
}

main {
  padding-block: 2em;
}

p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: .875rem;
  padding-inline: .5rem;
  line-height: 1.6em;
  max-width: 80ch;
  justify-self: start;

  @include at1200 {
    color: $text-light-color;
    padding-inline: clamp(2rem, 3vw, 5rem);
    font-size: clamp(1rem, 1vw, 1.375rem);
  }
}

p:not(:last-child) {
  margin-block-end: 1em;
}

main.contact p {
  margin-block-end: .25em;
  justify-self: center;

  @include at1200 {
    justify-self: start;
  }
}

em {
  font-style: normal;
  color: $em-dark-color;

  @include at1200 {
    color: $em-light-color;
  }
}