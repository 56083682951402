.hero {
  position: relative;
  grid-area: header-start / hero-start / hero-end / hero-end;
  background-image: url('./images/Lottie_cover.jpg?width=1200');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 2%;
  display:grid;

  @include at576 {
    background-position-x: 0;
  }

  @include at1024pt {
    background-image: url('./images/Lottie_cover.jpg?width=1920');
  }

  @include at1024ls {
    background-image: url('./images/Lottie_cover.jpg?width=1920');
  }

  @include at1200 {
    grid-area: header-start / hero-start / hero-end / content-end;
    background-image: url('./images/Lottie_cover.jpg?width=2560');
  }
}

.showreel-button {
  justify-self: center;
  align-self: end;
  margin-block-end: 1em;
  text-decoration: none;
  color: $text-light-accent-color;
  background-image: linear-gradient(180deg, $accent-color-gradient, $accent-color);
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  letter-spacing: .05em;
  border-radius: .5em;
  padding: .25em 1em;
  box-shadow: $shadow;

  @include at1200 {
    display: none;
  }
}

.hero svg {
  vertical-align: middle;
  margin-block-end: 2px;
}