/* COLORS */
@import 'https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;700&family=Poppins:wght@700;900&display=swap';
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  height: 100%;
  width: 100%;
}
html.noscroll {
  overflow: hidden;
}
body {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;
  background-color: #f9fafb;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 70vh auto;
  grid-template-areas: "header" "hero" "content";
}
@media (min-width: 1200px) {
  body {
    max-height: 100%;
    overflow: hidden;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: clamp(60px, 5vw, 80px) calc(100% - clamp(60px, 5vw, 80px));
    grid-template-areas: "header header" "hero content";
  }
}
body.noscroll {
  overflow: hidden;
}
h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #f1f7f9;
  letter-spacing: 0.05em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 8px rgba(0, 0, 0, 0.05), 0 2px 16px rgba(0, 0, 0, 0.05);
}
@media (min-width: 1200px) {
  h1 {
    font-size: clamp(1.5rem, 2vw, 2rem);
  }
}
h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1.25rem;
  color: #161818;
  letter-spacing: 0.05em;
  padding-inline: 0.5rem;
  text-align: center;
  margin-block-end: 1em;
}
@media (min-width: 1200px) {
  h2 {
    color: #f1f7f9;
    font-size: 1.5rem;
    text-align: start;
    padding-inline: clamp(2rem, 3vw, 5rem);
  }
}
@media (min-width: 1200px) {
  h2.bio-heading {
    display: none;
  }
}
main {
  padding-block: 2em;
}
p {
  font-family: "Merriweather Sans", sans-serif;
  font-size: 0.875rem;
  padding-inline: 0.5rem;
  line-height: 1.6em;
  max-width: 80ch;
  justify-self: start;
}
@media (min-width: 1200px) {
  p {
    color: #f1f7f9;
    padding-inline: clamp(2rem, 3vw, 5rem);
    font-size: clamp(1rem, 1vw, 1.375rem);
  }
}
p:not(:last-child) {
  margin-block-end: 1em;
}
main.contact p {
  margin-block-end: 0.25em;
  justify-self: center;
}
@media (min-width: 1200px) {
  main.contact p {
    justify-self: start;
  }
}
em {
  font-style: normal;
  color: #450811;
}
@media (min-width: 1200px) {
  em {
    color: #fee6ea;
  }
}
header {
  grid-area: header;
  z-index: 2;
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: center;
  padding-inline: 1em;
}
@media (min-width: 1200px) {
  header {
    padding-inline: clamp(2rem, 3vw, 5rem);
  }
}
.nav-wrapper {
  align-self: center;
  justify-self: end;
  height: 100%;
}
@media (min-width: 1200px) {
  .nav-wrapper {
    align-content: center;
  }
}
nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(217, 94, 112, 0.95);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 0;
  overflow: hidden;
}
@media (min-width: 1200px) {
  nav {
    position: relative;
    background-color: transparent;
    justify-content: end;
    height: 100%;
    overflow: visible;
    align-items: center;
  }
}
.menu-toggler:checked ~ nav {
  height: 100%;
}
nav > ul {
  margin-block-start: 50px;
  list-style: none;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1200px) {
  nav > ul {
    flex-direction: row;
    margin-block-start: 0;
  }
}
nav > ul > li {
  padding-block: 0.5em;
}
@media (min-width: 1200px) {
  nav > ul > li {
    padding-block: unset;
    padding-inline-start: 2.5em;
  }
}
nav > ul > li > a {
  text-decoration: none;
  color: #fbf9f9;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.05em;
}
@media (min-width: 1200px) {
  nav > ul > li > a {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
  }
}
nav > ul > li > a > svg {
  width: 2rem;
  vertical-align: middle;
  margin-block-end: 0.2em;
}
@media (min-width: 1200px) {
  nav > ul > li > a > svg {
    margin-block-end: 0.125em;
    width: clamp(1rem, 1.5vw, 1.5rem);
  }
}
@media (min-width: 1200px) {
  .showreel-link {
    color: #fbf9f9;
    background-image: linear-gradient(180deg, #c2707c, #d3455a);
    letter-spacing: 0.05em;
    border-radius: 0.5em;
    padding: 0.25em 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 8px rgba(0, 0, 0, 0.05), 0 2px 16px rgba(0, 0, 0, 0.05);
  }
}
.menu-toggler {
  position: absolute;
  top: -9999%;
  left: -9999%;
}
@media (min-width: 1200px) {
  .menu-toggler {
    display: none;
  }
}
.menu-toggler-label {
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 1200px) {
  .menu-toggler-label {
    display: none;
  }
}
.menu-toggler-label span,
.menu-toggler-label span::before,
.menu-toggler-label span::after {
  display: block;
  background: #f1f7f9;
  height: 3px;
  width: 2em;
  border-radius: 2px;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in;
}
.menu-toggler:checked ~ .menu-toggler-label span,
.menu-toggler:checked ~ .menu-toggler-label span::before,
.menu-toggler:checked ~ .menu-toggler-label span::after {
  background: #fbf9f9;
  transition: all 0.2s ease-in;
}
.menu-toggler-label span::before,
.menu-toggler-label span::after {
  content: "";
  position: absolute;
}
.menu-toggler:checked ~ .menu-toggler-label span::before,
.menu-toggler:checked ~ .menu-toggler-label span::after {
  transform-origin: center;
}
.menu-toggler-label span::before {
  top: -8px;
}
.menu-toggler-label span::after {
  top: 8px;
}
.menu-toggler:checked ~ .menu-toggler-label span {
  transform: rotate(45deg);
}
.menu-toggler:checked ~ .menu-toggler-label span::before {
  top: 0;
  transform: rotate(90deg);
}
.menu-toggler:checked ~ .menu-toggler-label span::after {
  top: 0;
}
.hero {
  position: relative;
  grid-area: header-start/hero-start/hero-end/hero-end;
  background-image: url("Lottie_cover.78678d90.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 2%;
  display: grid;
}
@media (min-width: 576px) {
  .hero {
    background-position-x: 0;
  }
}
@media (min-width: 1024px) and (orientation: portrait) {
  .hero {
    background-image: url("Lottie_cover.d40f5206.jpg");
  }
}
@media (min-width: 1024px) and (orientation: landscape) {
  .hero {
    background-image: url("Lottie_cover.d40f5206.jpg");
  }
}
@media (min-width: 1200px) {
  .hero {
    grid-area: header-start/hero-start/hero-end/content-end;
    background-image: url("Lottie_cover.3409d7d9.jpg");
  }
}
.showreel-button {
  justify-self: center;
  align-self: end;
  margin-block-end: 1em;
  text-decoration: none;
  color: #fbf9f9;
  background-image: linear-gradient(180deg, #c2707c, #d3455a);
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  border-radius: 0.5em;
  padding: 0.25em 1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 8px rgba(0, 0, 0, 0.05), 0 2px 16px rgba(0, 0, 0, 0.05);
}
@media (min-width: 1200px) {
  .showreel-button {
    display: none;
  }
}
.hero svg {
  vertical-align: middle;
  margin-block-end: 2px;
}
main {
  z-index: 1;
  display: grid;
  justify-content: center;
}
@media (min-width: 1200px) {
  main {
    grid-area: content-start/content-start/content-end/content-end;
    align-self: center;
  }
}
/*# sourceMappingURL=index.7ff4bf8c.css.map */
