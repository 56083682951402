@mixin at576{
  @media (min-width: 576px){
      @content;
  }
}

@mixin at768{
  @media (min-width: 768px){
      @content;
  }
}

@mixin at1024pt{
  @media (min-width: 1024px) and (orientation: portrait){
      @content;
  }
}

@mixin at1024ls {
  @media (min-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin at1200{
  @media (min-width: 1200px){
      @content;
  }
}